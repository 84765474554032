import { OptionType } from './select';

export const SELECT_OPTIONS_MOCK_DATA: OptionType[] = [
	{ value: 'AL', label: 'Alabama', isSelected: false },
	{ value: 'AK', label: 'Alaska', isSelected: false },
	{ value: 'AS', label: 'American Samoa', isSelected: false },
	{ value: 'AZ', label: 'Arizona', isSelected: false },
	{ value: 'AR', label: 'Arkansas', isSelected: false },
	{ value: 'CA', label: 'California', isSelected: false },
	{ value: 'CO', label: 'Colorado', isSelected: false },
	{ value: 'CT', label: 'Connecticut', isSelected: false },
	{ value: 'DE', label: 'Delaware', isSelected: false },
	{ value: 'DC', label: 'District Of Columbia', isSelected: false },
	{ value: 'FM', label: 'Federated States Of Micronesia', isSelected: false },
	{ value: 'FL', label: 'Florida', isSelected: false },
	{ value: 'GA', label: 'Georgia', isSelected: false },
	{ value: 'GU', label: 'Guam', isSelected: false },
	{ value: 'HI', label: 'Hawaii', isSelected: false },
];
