export interface User {
	userId: string;
	userToken: string;
	firstName: string;
	lastName: string;
	email: string;
	dateOfBirth: string;
	mobileNumber: string;
	mobileCode: string;
	personalTaxNo: string;
	countryId: number;
	userType: UserType;
	timeZone: string;
	primaryImg: string;
	currencyId: number;
	username: string;
	seoImage: string;
	isFreeSessionConsumed: boolean;
	isBusinessRegistered: boolean;
	isEmailVerified?: boolean;
	personalDetailsCompleted?: boolean;
	accountStatus: number;
	utmData: null;
	accountCategory: number;
	availabiltyData: string[];
	availability?: Availability;
	languages: Languages[];
	hourlyRateEuro: number;
	serviceDescription: string;
	videoBio: string;
	certification: Certification[];
	education: Education[];
	experience: Experience[];
	skills: string[];
	topics: Topics[];
	headline: string;
	categories: Categories[];
	isFreeSessionOpted: boolean;
	isMarketting?: boolean;
	suggestedSkill: string[];
	hourlyRate: string;
	hourlyRateWithSymbol: string;
	currency: Currency;
	country: Country;
	config: {
		switchToAp: boolean;
		receiveBookingInvoice: boolean;
	};
	social?: CalendarSettingsType;
	business: Business;
	mangopayUserId: string;
}

export enum UserType {
    not_defined = 0,
    ap = 1,
    ar = 2,
    both = 3,
}

export interface CalendarSettingsType {
	email: string;
	isGoogleConnected: boolean;
	isLinkedinConnected: boolean;
	isMicrosoftConnected: boolean;
	oauthTokenStatus: number;
}

export interface Languages {
	code: string;
	language: string;
	createdAt: string;
	searchKey: string;
	updatedAt: string;
	languageId: number;
}

export interface Certification {
	to: string;
	from: string;
	name: string;
	about: string;
}

export interface Education {
	to: string;
	from: string;
	field: string;
	university: string;
}

export interface Experience {
	to: string;
	from: string;
	title: string;
	company: string;
}

export interface Topics {
	title: string;
	topicDescription: string;
}

export interface Categories {
	createdAt: string;
	searchKey: string;
	updatedAt: string;
	categoryId: number;
	categoryName: string;
}

export interface Currency {
	currencyId: number;
	currencyCode: string;
	currencyName: string;
	currencySymbol: string;
}

export interface Country {
	countryId: number;
	countryCode: string;
	countryName: string;
}

export interface Skill {
	searchKey: string;
	skillId: number;
	skillName: string;
}
export interface Availability {
	isWeekdayOn: boolean;
	isWeekendOn: boolean;
	weekdayFromString: string;
	weekdayToString: string;
	weekendFromString: string;
	weekendToString: string;
}
export interface Business {
	businessId: number;
	businessName: string;
	businessRegistrationNo: string;
	typeOfBusiness: number;
	vatNo: string;
	userId: number;
	address: string;
	city: string;
	region: string;
	countryId: number;
	postalCode: string;
	countryCode: string;
	isSmallEnterprise: boolean;
}
