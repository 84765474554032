import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { Helmet } from 'react-helmet';
import YouTube from 'react-youtube';
import cx from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import Cookies from 'js-cookie';
import globalPropType from '../../config/proptype';
import { trackAdvert, updateProfile } from '../../actions/userAction';
import { fetchMasterData, fetchCountryCurrencyDetails } from '../../actions/masterAction';
import styles from './App.module.css';
import Routes from '../../routes';
import Footer from '../../components/Footer/Footer';
import thumbnailImg from '../../components/Common/assets/thumbnail.png';
import closebtn from '../../components/Common/assets/closebtn.svg';
import { DateTime } from 'luxon';
import 'react-toastify/dist/ReactToastify.css';
import { editProfile } from '../../services/userService';
import { setManogpayEnv } from '@conrati/utils';
import { Track } from '@conrati/tracking';
import { appActivityTracker } from '../../utils';

// Notification toast
toast.configure();

const youtubeVideoPlayerOptions = {
  height: '100%',
  width: '100%',
  playerVars: {
    autoplay: 0,
    rel: 0,
    modestbranding: 1,
  },
};

class App extends Component {
  static isUserLoggedIn = (e) => {
    if (e.isTrusted && e.key === 'app_status' && e.oldValue === 'true' && e.newValue === 'false') {
      return window.location.replace('/');
    }

    return true;
  };

  static onBeforeUnload = () => {
    // Set bpayOpen to false
    window.localStorage.setItem('bpayOpen', false);
  };

  static checkIfStaticPage = (location) => {
    const locationarr = location.split('/');
    if (locationarr.length >= 1) {
      const loc = locationarr[1];
      const ignorepage = ['company', 'help', 'legal'];

      if (ignorepage.includes(loc)) {
        return true;
      }
    }

    return false;
  };

  static saveCookieForPsychologistSeeker({ utmSource }) {
    // todo: 1. add a check to ignore logged-in users
    // todo: 2. what's the exact value for utm_source?

    if (utmSource) {
      // expires config is in days
      Cookies.set('seeking_advice_from_psychologist', '1', { expires: 1 });
    }
  }

  constructor(props) {
    super(props);
    this.state = {
      cookie: false,
      helmet: false,
      showAppPop: false,
      showPlayer: false,
      playerStarted: false,
      mobileApp: false,
    };

    this.interactTimeout = null;
  }

  componentDidMount() {
    console.log('Notice: Conrati Business Transition - Mid January 2025');
    // First launch
    this.getTimeZonAndUpdate();

    const utmData = this.storeUtmLocally();

    App.saveCookieForPsychologistSeeker(utmData);

    const { onFetchMasterData, masterdata, location, userState } = this.props;
    const { user, isLoggedIn} = userState;

    // Fetch country currency details

    // Launch Intercom
    this.launchIntercom();

    // Fetch masterdata from server.
    onFetchMasterData(masterdata);

    // Verify whether YouTube video has been played or not
    const videoPlayed = window.localStorage.getItem('video_played');
    if (location.pathname === '/' && !videoPlayed) {
      this.setState({ showPlayer: true });
    }

    // Cookie notice
    const cookie = window.localStorage.getItem('cookie-accepted');
    if (cookie) {
      this.setState({
        cookie,
      });
    }

    // React helmet for seo
    const locationUrl = location.pathname;
    if (locationUrl) {
      this.checkHelmetRequired(locationUrl);
    }

    // Ios application download popup
    // const showAppPop = this.showAppPopUpMessage(locationUrl);
    // if (showAppPop) {
    //   this.setState({ showAppPop: true });
    // }

    // Fetch analytics data
    this.getAnalyticalData(location.search);

    // Logged in status check
    window.addEventListener('storage', App.isUserLoggedIn);

    window.addEventListener('beforeunload', App.onBeforeUnload);

    // setup mangopay app environment
    const appEnv = process.env.REACT_APP_STAGE || 'production';
    setManogpayEnv(appEnv);

    if (isLoggedIn) {
        Track('page-view-client-landing-page', {
            email: user.email,
            name: `${user.firstName} ${user.lastName}`,
            userId: user.userId,
            loginType: user.loginType,
            timeZone: user.timeZone,
            isEmailVerified: user.isEmailVerified,
        });
    }

    appActivityTracker(
        'app/activity', 
        {
            email: user.email,
            userId: user.userId,
        }, 
        60 * 60 * 1000,  // 1 hour in milliseconds
        true,
        true
    );
  }

  componentDidUpdate(prevProps, prevState) {
    const { location } = this.props;
    const locationUrl = location.pathname;
    const prevLocation = prevProps.location.pathname;

    if (location !== prevLocation && !prevState.helmet) {
      this.checkHelmetRequired(locationUrl);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('storage', App.isUserLoggedIn);
  }

  storeUtmLocally = () => {
    const searchUrl = new URLSearchParams(window.location.search);

    if (
      searchUrl.has('utm_medium') ||
      searchUrl.has('utm_source' || searchUrl.has('utm_campaign'))
    ) {
      const utmMedium = searchUrl.get('utm_medium') || '';
      const utmSource = searchUrl.get('utm_source') || '';
      const utmCampaign = searchUrl.get('utm_campaign') || '';

      const storeData = {
        utmMedium,
        utmCampaign,
        utmSource,
      };

      window.localStorage.setItem('utmData', JSON.stringify(storeData));

      return storeData;
    }

    return {
      utmMedium: null,
      utmCampaign: null,
      utmSource: null,
    };
  };

  launchIntercom = () => {
    const { userState, userData } = this.props;
    const { isLoggedIn } = userState;
    const { email, firstName, lastName, userId, primaryImg, personalDetailsCompleted } = userData;

    let appId = 'o841xbrg';
    const appTest = 'ngkd7u3h';

    if (process.env.REACT_APP_STAGE === 'test' || process.env.REACT_APP_STAGE === 'dev') {
      appId = appTest;
    }

    if (process.env.REACT_APP_STAGE !== 'dev') {
      if (isLoggedIn) {
        let dataObject = {
          app_id: appId,
          email,
          user_id: userId,
          created_at: moment().unix(),
        };

        const userObject = {
          name: `${firstName} ${lastName}`,
          avatar: {
            type: 'avatar',
            image_url: primaryImg,
          },
        };

        if (personalDetailsCompleted) {
          dataObject = { ...dataObject, ...userObject };
        }

        window.Intercom('boot', dataObject);
      } else {
        window.Intercom('boot', {
          app_id: appId,
          // Website visitor so may not have any user related info
        });
      }
    }
  };

  onIntractTimeOut = () => {
    const { playerStarted } = this.state;
    if (!playerStarted) {
      this.setState({ showPlayer: false });
      const getcount = Number(window.localStorage.getItem('video_missed_count')) || 0;
      if (getcount === 1) {
        window.localStorage.setItem('video_played', true);
      }
      window.localStorage.setItem('video_missed_count', getcount + 1);
    }
  };

  showAppPopUpMessage = (location) => {
    const iosRegex = /iPad|iPhone|iPod/gm;
    const ifstaticpage = this.checkIfStaticPage(location);
    if (ifstaticpage) {
      return false;
    }

    if (iosRegex.test(navigator.userAgent) && !window.MSStream) {
      // window.location.replace(
      //   'https://apps.apple.com/app/conrati-consulting-on-demand/id1436821739'
      // );
      const prevStatus = window.localStorage.getItem('show_app_popup');
      const prevtime = window.localStorage.getItem('show_app_popup_time');
      const expiretime = moment.unix(prevtime).add(1, 'months');

      if (prevStatus) {
        if (prevtime && moment().isAfter(expiretime)) {
          return true;
        }
      } else if (!prevStatus && !prevtime) {
        return true;
      } else {
        return false;
      }
    }

    return false;
  };

  onClickCookieConsent = (e) => {
    e.preventDefault();
    window.localStorage.setItem('cookie-accepted', true);
    this.setState({
      cookie: true,
    });
  };

  onClosePlayer = (e) => {
    e.preventDefault();

    if (this.interactTimeout) {
      clearTimeout(this.interactTimeout);
    }

    this.setState({ showPlayer: false });
    const getcount = Number(window.localStorage.getItem('video_missed_count')) || 0;

    if (getcount === 1) {
      window.localStorage.setItem('video_played', true);
    }

    window.localStorage.setItem('video_missed_count', getcount + 1);
  };

  checkHelmetRequired = (location) => {
    const pathname = location.split('/');
    if (pathname.length === 2) {
      const blockedstring = [
        'signin',
        'register',
        'manifest.json',
        'favicon.ico',
        'dashboard',
        'payout',
        'availability',
        'mycalendar',
        'myprofile',
        'myaccount',
        'book',
        'reset-password',
        'search',
        'session',
        'sitemap.xml',
        'bookinghistory',
        '404',
      ];

      if (blockedstring.includes(pathname[1])) {
        this.setState({ helmet: true });
      }
    } else {
      this.setState({ helmet: true });
    }
  };

  onContinueToMobile = (e) => {
    e.preventDefault();
    this.setState({ showAppPop: false });

    window.localStorage.setItem('show_app_popup', true);
    window.localStorage.setItem('show_app_popup_time', moment().unix());
  };

  onPlay = () => {
    this.setState((prevstate) => {
      if (!prevstate.playerStarted) {
        return {
          playerStarted: true,
        };
      }
      return null;
    });
  };

  onEnd = () => {
    window.localStorage.setItem('video_played', true);
    this.setState({ showPlayer: false });
  };

  onReady = () => {
    const videoPlayed = window.localStorage.getItem('video_played');

    if (!videoPlayed) {
      this.interactTimeout = setTimeout(this.onIntractTimeOut, 10000);
    }
  };

  getAnalyticalData = (query) => {
    const { onTrackAdvert } = this.props;
    const searchParams = new URLSearchParams(query);

    console.log(searchParams.has('open_in_app'))

    if(searchParams.has('open_in_app') && searchParams.get('open_in_app') === 'true') {
      this.setState({ mobileApp: true });
    }
    
    if (
      searchParams.has('utm_source') ||
      searchParams.has('utm_medium') ||
      searchParams.has('utm_campaign')
    ) {
      onTrackAdvert({
        utm_source: searchParams.get('utm_source') || '',
        utm_medium: searchParams.get('utm_medium') || '',
        utm_campaign: searchParams.get('utm_campaign') || '',
      });
    }
  };

  getTimeZonAndUpdate = () => {
    const { isLoggedIn } = this.props.userState;

    if (isLoggedIn) {
      const { timeZone } = this.props.userData;
      const localDate = DateTime.local();

      if (timeZone !== localDate.zoneName) {
        editProfile({
          timezone_offset_short: localDate.zoneName,
          time_zone: localDate.zoneName,
        })
          .then(() => this.props.onUpdateProfile())
          .catch((err) => {
            throw err;
          });
      }
    }
  };

  render() {
    const { helmet, showAppPop, showPlayer, cookie, mobileApp } = this.state;

    let shownCookie = cookie;

    if (location.pathname.includes("/experience/payment")) {
        shownCookie = true;
    }

    return (
      <div className={styles.App}>
        {helmet && (
          <Helmet>
            <meta
              name="description"
              content="Conrati is a B2B and B2C marketplace for consulting on demand. Join the fast-lane for consulting!  From calendar integration to bookings, video consultations, automated invoicing, payments and more."
            />
            <meta property="og:title" content="Conrati" />
            <meta
              property="og:description"
              content="Conrati is a B2B and B2C marketplace for consulting on demand. Join the fast-lane for consulting!  From calendar integration to bookings, video consultations, automated invoicing, payments and more."
            />
            <meta property="og:image" content="https://cdn.conrati.com/conrati-web.png" />
            <meta
              property="og:image:secure_url"
              content="https://cdn.conrati.com/conrati-web.png"
            />
            <meta property="og:url" content="https://www.conrati.com/" />
            <meta property="twitter:url" content="https://www.conrati.com/" />
            <meta property="twitter:title" content="Conrati" />
            <meta
              property="twitter:description"
              content="Conrati is a B2B and B2C marketplace for consulting on demand. Join the fast-lane for consulting!  From calendar integration to bookings, video consultations, automated invoicing, payments and more."
            />
            <meta property="twitter:image" content="https://cdn.conrati.com/conrati-web.png" />
            <title>Conrati</title>
          </Helmet>
        )}
        <div className={showAppPop || showPlayer ? cx(styles.core, styles.fixedbg) : styles.core}>
          {showAppPop && !mobileApp  && <div className={styles.opaquebg} />}
          {showPlayer && !mobileApp &&  (
            <div className={styles.player}>
              <div className={styles.playermain}>
                <div
                  className={styles.closebtn}
                  onClick={this.onClosePlayer}
                  onKeyPress={() => {}}
                  role="button"
                  tabIndex="0"
                >
                  <img src={closebtn} alt="Close" />
                </div>
                <h4 className={styles.yttext}>What is Conrati?</h4>
                <div className={styles.ytbox} style={{ backgroundImage: `url(${thumbnailImg})` }}>
                  <YouTube
                    videoId="o0X_xJU7LUE"
                    opts={youtubeVideoPlayerOptions}
                    onPlay={this.onPlay}
                    onEnd={this.onEnd}
                    containerClassName={styles.ytboxpl}
                    onReady={this.onReady}
                  />
                </div>
                <button type="button" className={styles.ytcontbtn} onClick={this.onClosePlayer}>
                  CONTINUE TO WEBSITE
                </button>
              </div>
              <div className={styles.playerbg} />
            </div>
          )}
          <Routes />
          {!shownCookie && !showPlayer && !mobileApp && (
            <div className={styles.cookie}>
              <div className={styles.cookieas}>
                <p>By continuing to use this website, you consent to the use of cookies.</p>
                <Link to="/legal/privacy-policy" target="_blank" className={styles.cookier}>
                  Read More
                </Link>
              </div>
              <div>
                <button
                  type="button"
                  aria-label="Cookie Consent"
                  onClick={this.onClickCookieConsent}
                  className={styles.btn}
                >
                  <svg aria-hidden="true" width="18" height="18" viewBox="0 0 18 18">
                    <path d="M15 4.41L13.59 3 9 7.59 4.41 3 3 4.41 7.59 9 3 13.59 4.41 15 9 10.41 13.59 15 15 13.59 10.41 9 15 4.41z" />
                  </svg>
                </button>
              </div>
            </div>
          )}
        </div>
        {!mobileApp && <Footer />}
        {showAppPop && !showPlayer && !mobileApp (
          <div className={styles.appopup}>
            <p className={styles.appptext}>Conrati is now available on app store.</p>
            <a
              className={styles.btnd}
              href="https://apps.apple.com/app/conrati-consulting-on-demand/id1436821739"
            >
              DOWNLOAD
            </a>
            <button type="button" onClick={this.onContinueToMobile} className={styles.mappptext}>
              Continue to mobile site
            </button>
          </div>
        )}
      </div>
    );
  }
}

App.propTypes = {
  masterdata: PropTypes.shape({
    categories: PropTypes.shape([]),
    skills: PropTypes.shape([]),
    languages: PropTypes.shape([]),
    currencies: PropTypes.shape([]),
    countries: PropTypes.shape([]),
    lastUpdated: PropTypes.string,
  }).isRequired,
  onFetchMasterData: PropTypes.func.isRequired,
  onTrackAdvert: PropTypes.func.isRequired,
  location: globalPropType.location.isRequired,
  userState: globalPropType.userState.isRequired,
  userData: globalPropType.user.isRequired,
};

const mapStateToProps = (state) => {
  return {
    masterdata: state.masterdata,
    userState: state.userState,
    userData: state.userState.user,
  };
};

const mapActionToProps = (dispatch) => {
  return bindActionCreators(
    {
      onFetchMasterData: fetchMasterData,
      onFetchCountryCurrencyDetails: fetchCountryCurrencyDetails,
      onTrackAdvert: trackAdvert,
      onUpdateProfile: updateProfile,
    },
    dispatch
  );
};

export default withRouter(connect(mapStateToProps, mapActionToProps)(App));
